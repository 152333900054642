<template>
  <div class="special">
    <div class="image">
      <img src="../assets/image/spacialBanner.bc11f27.png" alt="" style="width: 100%; height: 100%;">
    </div>
    <div class="layout">
      <div class="listHd clearfloat">
        <div class="fl listHdGrop">
          {{$t('m.listPageOption')}}：<a href="/" style="font-weight: 700; color: #303133;">{{$t('m.listPageHome')}}</a> > 
          <span style="color: #0162b3;">&nbsp;专题专栏</span>
        </div>
      </div>
      <div class="specialContent">
        <ul>
          <li v-for="(item, index) in specialLIst" :key="index">
            <a :href="`/listThird/${item.entTChannelUuid}`">
              <div>
                <div class="img">
                  <img :src="item.fileName" class="imaeg">
                </div>
                <p>
                  <span></span>
                  <i>{{item.name}}</i>
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div style="clear: left; margin-bottom: 50px; "></div>
    </div>
    
  </div>
</template>
<script>
import { getChildChannel } from '@/api/common'
export default {
  name: 'list',
  data(){
    return {
      specialLIst:[
        // {
        //   img: require('../assets/image/special1.png'),
        //   title:'喜迎二十大 奋进新时代',
        // },
        // {
        //   img:require('../assets/image/special2.png'),
        //   title:'学“习”园地',
        // },
        // {
        //   img:require('../assets/image/special3.png'),
        //   title:'不忘初心牢记使命',
        // },
        // {
        //   img:require('../assets/image/special4.png'),
        //   title:'集团法制宣传专栏',
        // }
      ],
      parentId: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName1: '',
      slideHdName: '',
      slideHdName2: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [],
      newsitem: {},
      indexImg: '',
    }
  },
  mounted(){
    var _this = this
    _this.special()
  },
  methods: {
    special(){
      var _this = this
      let data3 = {
        applicationUuid: _this.webId,
        parentUuid: '0190'
      }
      getChildChannel(data3).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.specialLIst = data
        }
      })
    }

  }
}
</script>

<style>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.listHd {
  height: 50px;
  display: flex;
  line-height: 50px;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: .6;
}
.special .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.special .specialContent{
  min-width: 1200px;
  min-height: 200px;
  margin-right: -30px;
  margin-top: 10px;

}
.special .specialContent ul {
  width: 100%;
  height: 100%;
}
.special .specialContent li {
  float: left;
  width: 380px;
  height: 200px;
  margin-right: 28px;
  margin-bottom: 18px;
}
.special .specialContent li .img {
  width: 380px;
  height: 160px;
}
.special .specialContent .imaeg {
  width: 100%;
  height: 100%;
}
.special .specialContent li p {
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  width: 380px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.special .specialContent li p span {
  display: inline-block;
  width: 3px;
  height: 15px;
  position: relative;
  top: 2px;
  background-color: #0162b3;
  margin: 0 10px 0 2px;
}
.special .specialContent em, i {
  font-style: normal;
}
.special .specialContent li:hover {
  transform: translateY(-5px) translateZ(10px);
  box-shadow: 0 0 10px #41a4f5;
  transition: .2s ease-in;
}

</style>
